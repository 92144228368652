import { LoadingPlugin } from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

import LSpinners from '@/vue/components/Loaders/Spinners.vue'
import LDots from '@/vue/components/Loaders/Dots.vue'
import LBars from '@/vue/components/Loaders/Bars.vue'

export const Loaders = { LSpinners, LDots, LBars }

export default LoadingPlugin
