import { ref } from 'vue'

const useScroll = () => {
  const animations = ref(['fade-up', 'fade-down', 'fade-left', 'fade-right'])

  const onScroll = (position, height) => {
    if (position < height) {
      return 'fade-out'
    }

    return 'fade-in'
  }

  const checkIfCurrentPosition = (position, bottom, element) => {
    var elementTop = element.offset().top
    var elementBottom = elementTop + element.height()
    return elementBottom >= position && elementTop <= bottom
  }

  const getAnimation = (position, bottom, element) => {
    if (!checkIfCurrentPosition(position, bottom, element)) {
      return ''
    }

    const className = animations.value.find((item) => {
      return element.hasClass(item)
    })

    if (!className) {
      return ''
    }

    return className
  }

  return { onScroll, getAnimation }
}

export default useScroll
