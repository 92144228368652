import GlobalComponents from '@/js/plugins/components'
import GlobalDirectives from '@/js/plugins/directives'
import SidebarPlugin from '@/js/plugins/sidebar'
import LoadingPlugin from '@/js/plugins/loading'
import SwiperPlugin from '@/js/plugins/swiper'
import i18n from '@/js/plugins/i18n'
import pinia from '@/js/plugins/pinia'
import Toast, { options } from '@/js/plugins/toast'

import 'bootstrap/scss/bootstrap.scss'
import '@/assets/scss/landing-page/index.scss'
import '@/assets/scss/other-pages/policy.scss'
import '@/assets/scss/other-pages/success.scss'
import '@/assets/scss/application-form/index.scss'

export default {
  install(app) {
    app.use(GlobalComponents)
    app.use(GlobalDirectives)
    app.use(Toast, options)
    app.use(SidebarPlugin)
    app.use(LoadingPlugin)
    app.use(SwiperPlugin)
    app.use(pinia)
    app.use(i18n)
  },
}
