import { library } from '@fortawesome/fontawesome-svg-core'

import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faGooglePlus } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebook, faTwitter, faGooglePlus, faQuoteRight)

export default FontAwesomeIcon
