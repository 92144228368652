<template>
  <div class="sidebar" :data-color="backgroundColor">
    <div class="logo">
      <router-link to="/admin/dashboard" v-slot="{ href }">
        <a :href="href" class="simple-text logo-mini">
          <div class="logo-image">
            <img :src="logo" />
          </div>
        </a>
      </router-link>

      <router-link to="/admin/dashboard" v-slot="{ href }">
        <a :href="href" class="simple-text logo-normal">
          {{ title }}
        </a>
      </router-link>

      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-outline-white btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
          <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
        </button>
      </div>
    </div>

    <div class="sidebar-wrapper">
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vnud-sidebar',
  props: {
    title: {
      type: String,
      default: 'ADMIN PANEL'
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    logo: {
      type: String,
      default: 'img/icon-vue.png'
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    }
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  }
}
</script>

<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.logo > a {
  text-decoration: none !important;
}

#minimizeSidebar.btn {
  margin-top: 8px !important;
  padding: 11px 22px !important;

  i {
    padding-right: 0px !important;
  }
}
</style>
