// global components
import CountryFlag from 'vue-country-flag-next'
import FontAwesomeIcon from '@/js/plugins/fontawesome'
import { Loaders } from '@/js/plugins/loading'
import Slider from '@/vue/components/Slider.vue'

const GlobalComponents = {
  install(app) {
    app.component('font-awesome-icon', FontAwesomeIcon)
    app.component('l-spinners', Loaders.LSpinners)
    app.component('l-dots', Loaders.LDots)
    app.component('l-bars', Loaders.LBars)
    app.component('country-flag', CountryFlag)
    app.component('slider', Slider)
  },
}

export default GlobalComponents
